.box-contact {
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 120px;
}

.box-item {
  height: 80%;
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.text-contact {
  line-height: 40px;
  font-size: 1.2rem;
  color: white;
  text-align: left;
}

.box-item-fullwidth {
  height: 20%;
  width: 100%;
  display: flex;
}

.item-fullwidth {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo-footer{
  height: 50px;
  width: 200px;
  background-image: url("../../../public/asset/logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.link{
  text-decoration: underline;
  font-size: 1.4rem;
}

.link:hover {
  text-decoration: underline dotted; 
  color: rgb(248 194 1 / var(--tw-text-opacity));
}

@media (max-width: 500px) {
  .box-contact {
    padding: 20px 20px;
  }

  .text-contact {
    font-size: 0.8rem;
    line-height: 25px;
  }
}
