.box-about {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 0.8px solid grey;
}

.box-about-icon {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f3d1bf;
}

.about-icons {
  height: 100%;
  width: 100%;
  background-image: url("../../../public/asset/about-icons.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.box-mid-about-wrapper {
  height: 60vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

.box-mid-about {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.img-about-mid {
  height: 80%;
  width: 50%;
  background-image: url("../../../public/asset/about-mid.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.img-about-mid-left {
  height: 80%;
  min-width: 455px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 30px;
}

.box-about-bottom {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 16px;
}

.item-about-bottom {
  height: 80%;
  width: 33.33%;
  border: 1px solid grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.title-box {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 3px;
  text-align: left;
  color: "#1D1B1A";
}

.title-box-2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1px;
  text-align: left;
}

.title-box-3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  color: #391400a3;
}

.button-box {
  min-height: 48px;
  width: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
}

.text-button-box {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
}

.button-box:hover {
  color: #ffc200;
  background-color: #1e1f21;
}

@media (min-width: 800px) {
  .box-about {
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 120px;
  }
}

@media (max-width: 800px) {
  .box-about {
    padding: 0px 40px;
  }
}
@media (max-width: 445px) {
  .title-media-query {
    display: none;
  }
}

@media (max-width: 1150px) {
  .img-about-mid,
  .button-box {
    display: none;
  }
  .box-about-bottom {
    padding: 0px 0px;
  }
  .img-about-mid-left {
    min-width: 100%;
  }
  .box-mid-about {
    min-height: 45vh;
  }
}

@media (max-width: 610px) {
  .box-about-bottom {
    padding: 0px 0px;
  }

  .img-about-mid-left {
    width: 100vw;
  }
  .img-about-mid-left {
    min-height: 100vh;
  }

  .box-mid-about {
    padding-top: 25px;
    height: 76vh;
  }

  .img-about-mid-left {
    padding-right: 0px;
  }

  .title-media-query {
    font-size: 1.4rem;
  }
}
