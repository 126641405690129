.box-about-1 {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #ffffff;
  border: 0.8px solid grey;
}

.about-left {
  height: 100vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 120px;
  padding-top: 50px;
}

.about-right {
  height: 100%;
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-left: 50px;
  padding-bottom: 50px;
}

.img-about {
  background-image: url("../../../public/asset/about-1-.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.box-left-top {
  min-height: 4vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 3px;
  text-align: left;
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  color: #391400a3;
  margin-top: 20px;
}

.letter-spacing-1 {
  letter-spacing: -1px;
}

.i {
  height: 40px;
  width: 40px;
  background-image: url("../../../public/asset/icon-happy.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.title-bold {
  font-size: 36px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1px;
  display: inline-block;
}

@media (max-width: 1050px) {
  .about-right {
    display: none;
  }

  .about-left {
    width: 100%;
    padding-right: 120px;
  }
}

@media (max-width: 800px) {
  .about-left {
    padding: 40px 40px 0px 40px;
  }

  .box-left-top {
    min-height: auto;
  }
}

@media (max-width: 500px) {
  .display-mobile {
    display: flex;
  }
  .display-desktop {
    display: none;
  }
}
@media (min-width: 501px) {
  .display-mobile {
    display: none;
  }
  .display-desktop {
    display: flex;
  }
}
