.box-join {
  height: 100vh;
  width: 100%;
  padding: 50px 120px 20px 120px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #ffffff;
}

.join-left {
  height: 300px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.img-join {
  height: 280px;
  width: 300px;
  background-image: url("../../../public/asset/prueba2.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
}

.join-right {
  height: 300px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 40px;
}

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 3px;
  text-align: left;
  color: #391400a3;
}

.description {
  color: black;
  line-height: 42px;
  font-size: 1.2rem;
  font-weight: bold;
}

.text {
  color: #391400a3;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
}

.bottom {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0px 100px;
}

.text-bold {
  font-size: 36px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1px;
  display: inline-block;
  color: black;
}

@media (max-width: 1024px) {
  .join-left {
    display: none;
  }

  .join-right {
    width: 100%;
  }
  .bottom {
    padding: 0px 40px;
  }
}

@media (max-width: 768px) {
  .box-join {
    padding: 40px 40px;
  }

  .join-right {
    padding-left: 0px;
  }

  .bottom {
    padding: 0px;
    justify-content: flex-end;
  }

  .description {
    line-height: 40px;
  }

  .text-display {
    display: none;
  }
}

@media (max-width: 375px) {
  .text-display-375 {
    display: none;
  }
}

@media (max-width: 768px) {
  .box-join {
    padding: 40px 40px;
  }

  .join-right {
    padding-left: 0px;
  }

  .bottom {
    padding: 0px;
    justify-content: flex-end;
  }

  .description {
    line-height: 32px;
  }

  .text-display {
    display: none;
  }
}
