.box-team {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px 120px 0px 120px;
}

.title-team {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 3px;
  text-align: left;
}

.title-2-team {
  font-size: 36px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1px;
  display: inline-block;
}

.box-slider {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.box-arrow {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon-arrow {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-left {
  height: 30px;
  width: 30px;
  background-image: url("../../../public/asset/arrow-left.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.arrow-right {
  height: 30px;
  width: 30px;
  background-image: url("../../../public/asset/arrow-right.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.box-item-slider {
  height: auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.img-slider {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.box-points {
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.point {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #282c31;
  margin-left: 7px;
}

.title-text {
  color: #282c31;
  font-weight: bold;
}

.subtitle-text {
  color: #282c31;
}
