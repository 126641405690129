@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,500&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  user-select: none;
}

#root {
  background-color: #ffffff;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.home-h2 {
  letter-spacing: -2px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.nav {
  background-color: hsl(213, 10%, 17%);
}

@media (max-width: 936px) {
  .nav {
    padding: 0px 70px;
  }
}

.nav > .nav-header {
  display: inline;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  height: 100%;
  width: 120px;
  background-image: url("../public/asset/logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links > p:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.background-image {
  background-image: url("../public/asset/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #282c31;
  background-attachment: fixed;
}

.box-button-home {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.button-how-we-work {
  height: 48px;
  width: 154px;
  background-color: #ff4265;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.nav > #nav-check {
  display: none;
}

@media (max-width: 1150px) {
}

@media (max-width: 1015px) {
}

@media (max-width: 770px) {
  .background-image {
    background-position: left;
  }
}

@media (max-width: 555px) {
}
@media (max-width: 733px) {
}
@media (max-width: 688px) {
}
@media (max-width: 610px) {
}
@media (max-width: 528px) {
}
@media (max-width: 450px) {
}

@media (max-width: 385px) {
  h2 {
    font-size: 3.6rem;
    text-align: center;
  }

  .box-button-home {
    justify-content: center;
  }
}
@media (max-width: 750px) {
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
    margin-right: 5px;
  }
  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #282c31;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }
  .nav > .nav-links > p {
    display: block;
    width: 100%;
    border-bottom: 0.8px solid grey;
    letter-spacing: 2px;
    padding-left: 22px;
    color: #ffffff;
    font-weight: 800;
  }
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
  .nav {
    padding: 0px 20px;
  }
}
